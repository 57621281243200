<template>
  <review-card
    :key="`list-groupstatus-patient${expanded}`"
    title="Pares impactados"
  >
    <v-list-group
      v-for="(item, index) in pares"
      :key="`list-group-par-${index}`"
      no-action
      :value="expanded"
    >
      <template v-slot:activator>
        <v-list-item class="py-2 px-0 item-par">
          <v-list-item-content>
            <v-list-item-title class="body-2">
              {{ item.par.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <div>
        <tracking-impaction-point-view-sides
          class="grey"
          :tracking-point="item.par.points.trackingPoint"
          :impaction-point="item.par.points.impactionPoint"
          :tracking-point-side="item.trackingPointSide"
          :impaction-point-side="item.impactionPointSide"
        />

        <div class="ml-3 mt-2 body-2 px-4 py-2">
          <div class="d-flex align-baseline">
            <property-value property="Tipo" :value="item.par.type.name" />
            <explanation-icon
              v-if="item.par.type.slug"
              :explanation="getExplanationTypeBySlug(item.par.type.slug)"
              :name="item.par.type.name"
            />
          </div>
          <property-value
            property="Impactar em casa"
            :value="item.patientImpactAtHome | booleanToString"
          />
          <property-value
            property="Comentários"
            :value="item.patientComments"
          />
          <property-value
            property="Sintomas"
            :value="item.symptoms | arrayToString"
          />
          <property-value
            v-if="!item.hidePatient"
            property="Patógenos"
            :value="item.pathogens | arrayToString"
          />
          <property-value
            property="Categorias"
            :value="item.categories | arrayToString"
          />
        </div>
      </div>
    </v-list-group>
  </review-card>
</template>

<script>
export default {
  props: {
    pares: {
      type: Array,
      default: () => [],
    },
    types: {
      type: Array,
      default: () => [],
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isBreakpointXs() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    getExplanationTypeBySlug(slug) {
      return this.types.find((type) => type.slug === slug)?.explanation;
    },
  },
};
</script>

<style></style>
